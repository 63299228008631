.reviews-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: column;
    align-items: center;
}

.category-name {
    display: flex;
    justify-content: center;
}

.review-item {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e0e0e0;
    padding: 10px;
    width:969px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    min-height: 150px;
    padding-bottom: 2px;
}

.review-item img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-right: 10px;
}

.review-content {
    display: flex;
    flex-grow: 1;
}

.review-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin-right: 20px;
}

.review-product-name {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.review-text {
    font-size: 14px;
    color: #888;
    margin-bottom: 5px;
    max-height: 80px;
    overflow-y: auto;
    padding-right: 10px;
    word-wrap: break-word;
    white-space: pre-wrap;
}


.review-rating {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    margin-bottom: 5px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.review-btn {
    background-color: #fddd4e;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100px;
    text-align: center;
}

.edit-review-btn:hover,
.delete-review-btn:hover {
    background-color: #fccc2e;
}

.delete-review-btn {
    background-color: #ff6b6b;
    color: white;
}

.delete-review-btn:hover {
    background-color: #ff8888;
}

#root .review-item .delete-review-btn{
    font-size:12px;
    font-weight:500;
}

#root .review-details a{
    color:#000000;
}

#root .review-details a:hover{
    color:#ad7412;
}

.empty-reviews-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 50px;
    text-align: center;
}

.show-products-btn {
    background-color: #fddd4e;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
}

.show-products-btn:hover {
    background-color: #fae275;;
}

#root h1{
    justify-content:center;
    align-items:center;
    left:4px;
}

#root .empty-reviews-container p{
    font-weight:500;
}

#root .empty-reviews-container img{
    width:568px;
}

#root .show-products-btn{
    position:relative;
    top:33px;
}

#root .review-item .rating-container{
    color:#db9900;
}

#root .review-item .delete-review-btn{
    position:relative;
    bottom:-53px;
}

#root .review-item .fa-star{
    cursor: pointer;
}

#root .review-item .fa-star:hover{
    color:#ffb406;
}

.rating-container .star {
    color: #e0e0e0;
    cursor: pointer;
    margin-right: 3px;
}

.rating-container .star.active {
    color: #db9900;
}

.rating-container .star:hover {
    color: #ffb406;
}

.hovered-rating-text {
    margin-left: 5px;
    font-size: 14px;
    color: #333;
}

.edit-review {
    position: relative;
    width: 500px;
    height: 50px;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
    max-height: 80px;
    overflow-y: auto;
}

#root .review-item p{
    width:565px;
   }

/* Delete review */
#root .review-item .delete-review-btn{
    transform:translatex(0px) translatey(43px);
   }
