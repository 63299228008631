body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

::web-kits-scrollbar {
  display: none;
}


.nav-bar {
  display: flex;
  z-index: 3;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(19,25,33);
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.nav-list a i {
  margin-left: 10px;
  padding-right: 55px;
}

.nav-bar .nav-list .nav-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.nav-list li {
  margin: 0 15px;
}

.logo-image {
  max-width: 400px;
  max-height: 80px;
  margin-left: -20px;
  margin-right: 30px;
}

.nav-search-bar {
  flex: 1;
  padding: 5px 10px;
  border-radius: 4px;
}

.nav-search-button {
  margin-left: 40px;
  padding: 5px 10px;
  cursor: pointer;
}

.nav-login-item a {
  color: white;
  text-decoration: none;
}

.nav-login-item:hover a {
  text-decoration: underline;
}

.nav-list input[type=text]{
  min-height:28px;
  width:708px;
 }

.profile-container {
  position: relative;
  z-index: 10;
  align-self: center;
  align-content: center;
  justify-content: center;
  display: flex;
}

/* List Item */
.profile-dropdown .user-details li{
  width:256px;
 }

 .actions li button{
  position:relative;
  left:26px;
 }



.profile-dropdown {
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 300px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
}
.nav-login-item ul{
  transform:translatex(-148px) translatey(12px);
 }


 .profile-dropdown {
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
}

.profile-dropdown {
  flex: 1;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center; /* vertically center the name */
  text-decoration: none;
  list-style-type: none;
}

.profile-dropdown .user-details li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: 5px 0;
  text-align: center;
}

.username-info {
  display: block;
  text-align: center;
  width: 100%;
  padding: 15px 0;
}

.profile-dropdown .actions {
  flex: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 10px;
  list-style-type: none;
  align-self: center;
  transform: translatex(6px);
}

.profile-dropdown .actions button {
  background-color: #fddd4e;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 5px 0;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
  max-width: 120px;
  width: 130px;
}

.profile-dropdown .actions li {
  display: flex;
  justify-content: center;
}

.profile-dropdown .actions button:hover {
  background-color: #fccc2e;
}

.profile-dropdown.hidden {
  display: none;
}

.nav-categories-container {
  display: flex;
  justify-content: center;
  background-color: rgb(36,47,62);
  padding: 10px 0;
  top: 100px;
  width: 100%;
  z-index: 2;
}

.nav-categories-container a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

/* Cart Icon */
.nav-list a i{
  font-size:26px;
 }


.nav-categories-container a:hover {
  background-color: rgb(51, 67, 89);
}

.profile-text {
  color: white;
  cursor: pointer;
}

.nav-search-container {
  flex-grow: 1;
  display: flex;
  position: relative;
}

.nav-search-bar {
  padding: 5px 10px;
  border-radius: 4px 0 0 4px;
  border: 1px solid #ddd;
  width: 100%;
}

.nav-search-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  border-radius: 0 4px 4px 0;
  background-color: #333;
  border: none;
  cursor: pointer;
  color: #FFF;
}

.nav-list a i.fa-solid.fa-cart-shopping {
  color: #FFF;
}

.nav-list .nav-search-button{
  background-color: #FEBD6A;
}

.nav-list .nav-search-button .fa-magnifying-glass{
  color:#1c1616;
}

/* Button */
.actions li button{
  transform:translatex(-27px) translatey(0px);
 }

 /* Button */
 #root .app-container .nav-bar .nav-list .nav-login-item .profile-container .profile-dropdown .actions li button{
  width:122% !important;
 }
