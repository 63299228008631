/* General Styling */
.purchasesContainer_unique {
    width: 80%;
    margin: 2rem auto;
    padding: 1rem;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.cartComp-quantity-select {
    width: fit-content;
    border-radius: 6px;
    margin: 10px 0;
    border-color: #D5D9D9;
    background-color: rgba(173, 173, 173, 0.226);
    font-size: 13px;
    outline: none;
}

.purchasesContainer_unique h1 {
    text-align: center;
    color: #333;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.purchasesContainer_unique p {
    text-align: center;
    color: #777;
}

/* List Styling */
.purchasesContainer_unique li.purchaseItem_unique {
    padding: 1rem;
    margin: 1rem 0;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative; /* Make the container relative for absolute positioning */
}

.purchasesContainer_unique img.purchaseImage_unique {
    width: auto;
    height: 100px;
    object-fit: cover;
}

.purchasesContainer_unique div.purchaseDetails_unique {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

/* Text Styling */
.purchasesContainer_unique div {
    color: #555;
}

.timerContainer_unique {
    position: absolute;
    top: 0;
    right: 0;
}

.updateContainer_unique {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.deleteContainer_unique {
    position: absolute;
    bottom: 0;
    right: 0;
}

/* MODAL STYLING DELETE */
.modalOverlay_unique {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContainer_unique {
    width: 350px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

#root ul .cartComp-review-button{
    margin-left:3px;
   }



.modalButtonsContainer_unique {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.button-design {
    cursor: pointer;
    max-width: 100%;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #fddd4e;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.button-design:hover {
    background-color: #fccc2e;
}

/* MODAL STYLING ALREADY REVIEWED */
.modal-already-reviewed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-already-reviewed-content {
    width: 300px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
  }

  .modal-already-reviewed-content h2 {
    margin-top: 0;
  }

  .modal-already-reviewed-content p {
    margin: 20px 0;
  }

  .modal-already-reviewed-content button {
    padding: 10px 20px;
    border: none;
    background-color: #e74c3c;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .modal-already-reviewed-content button:hover {
    background-color: #c0392b;
  }

  .X-button {
    position: sticky;
    transform: translatex(147px) translatey(-26px) !important;
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: #e74c3c; /* Red color to match the theme */
    transition: color 0.3s ease;
}

/* NOT LOGGED IN */
.not-logged-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh; /* take the full viewport height */
}

.not-logged-centered-logo {
    width: 300px;
    margin-bottom: 20px;
}
