.products-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: column;
    align-items: center;
}

.sorting-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    padding-left: 16%;
}

.sort-quantity-select {
    width: fit-content;
    border-radius: 6px;
    width: 100px;
    height: 20px;
    margin: 10px 0;
    border-color: #D5D9D9;
    background-color: rgba(173, 173, 173, 0.226);
    font-size: 13px;
    outline: none;
}

.product-item .product-info-right{
    position:relative;
    top:-25px;
   }


.prime-logo {
    width: 80px;
}

::web-kits-scrollbar {
    display: none;
  }

.category-name{
    display:flex;
    justify-content:center;
   }

.product-name a{
    font-size: 23px;
    text-decoration:none;
    color:#000000;
   }

.product-name a:hover {
    text-decoration: underline;
}

.product-item {
    display: flex;
    border-top: 0.5px solid #d1d1d1;
    padding: 10px;
    width: 1300px;
    height: 250px;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image-container {
    width: 250px;
    height: 250px;
    overflow: hidden;
    position: relative;
}

.product-info-right {
    justify-content: space-between;
}

.product-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 25px;
}

.product-name {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
    text-decoration: none;
}

.review-placeholder {
    font-size: 14px;
    color: #888;
    margin-bottom: 5px;
}

.product-price {
    font-size: 25px;
    color: #000;
    font-weight: bold;
}

.star {
    font-size: 20px;
    color: #FFA41C;
}

.starrating {
    padding-top: 3px;
}

.spacing {
    padding-top: 90px;
}

/* Description */
#root .app-container div .products-container .product-item .product-details .description{
    width:62% !important;
   }

   /* Image */
   #root .product-item img{
    padding-top:23px;
   }
