.cartComp-cart {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#root .cartComp-cart {
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

.cartComp-cart-item {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.cartComp-product-details {
    display: flex;
}

.cartComp-product-image {
    width: 80px;
    margin-right: 20px;
}

/* Delete button */
.cartComp-cart ul .delete-button{
    transform:translatex(99px) translatey(0px);
   }



.cartComp-product-name,
.cartComp-product-description,
.cartComp-product-price,
.cartComp-product-quantity {
    margin: 5px 0;
}

.cartComp-product-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.cartComp-update-quantity-button,
.cartComp-remove-button,
.cartComp-review-button {
    background-color: #fddd4e;
    border: none;
    margin: 5px 0;
    padding: 5px 10px;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
    border-radius: 3px;
    text-decoration: none;
}

.cartComp-confirm-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    z-index: 1000;
}

.cartComp-error-message {
    color: red;
}

.cartComp-grand-total {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.cartComp-complete-purchase-button {
    background-color: #fddd4e;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    width: 500px;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: box-shadow 0.3s ease-in-out;
}

.cartComp-complete-purchase-button:hover {
    box-shadow:0px 0px 3px 0px #000000;
}

.cartComp-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    gap: 20px;
}

.cartComp-website-logo {
    max-width: 500px;
    height: auto;
}

.cartComp-login-button {
    background-color: #fddd4e;
    border: none;
    margin-top: 30px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
}

.cartComp-login-button p {
    text-decoration: none;
}

.cartComp-plz-login {
    font-size: 22px;
    font-weight: 700;
}

.cartComp-confirm-modal {
    background-color: rgb(166, 165, 165);
    border-radius: 8px;
    box-shadow:-1px 8px 25px -19px #000000;
}

.delete-text {
    color: white;
}

.delete-button {
    background-color: #fddd4e;
    justify-content: center;
    border-radius: 3px;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
    border: none;
}

.delete-button:hover {
    box-shadow:0px 0px 5px 0px #000000;
}

.cartComp-cart ul .delete-button{
 flex-direction:row;
 display:inline-flex;
 align-items:center;
 margin-left:53px;
 padding-left:25px;
 padding-right:29px;
}

.cartComp-cart ul li{
    width:757px;
   }


   .cartComp-cart-item .cartComp-product-actions .cartComp-review-button{
    margin-left:0px;
    width:117px;
   }


   .cartComp-cart-item .cartComp-product-actions .cartComp-remove-button{
    margin-left:0px;
    width:116px;
   }

   .cartComp-action-button {
    background-color: #fddd4e;
    border: none;
    margin: 5px 0;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    transition: box-shadow 0.2s ease-in-out;
}

.cartComp-action-button:hover {
    box-shadow:0px 0px 3px 0px #000000;
}
