#root {
    font-family: Arial, Helvetica, sans-serif;
}

.product-page {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.product-page__top-section {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 20px;
}

.product-page__image-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 512px;
    min-width: 300px;
    padding: 30px;
}

.product-page__image-container img {
    width: 400px;
    height: 400px;
    object-fit: contain;
}

.product-page__center {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.product-page__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
    padding-right: 90px;
    border-radius: 3px;
}

.product-page__right p {
    font-weight: bold;
}

.product-page__right button {
    background-color: #fddd4e;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    width: 150px;
    transition: box-shadow 0.3s ease-in-out;
}

.free-shipping{
    color: black;
}

.product-page__right button:hover {
    box-shadow:0px 0px 3px 0px #000000;
}

.product-price-2 {
    font-size: 30px;
}

.product-reviews-container {
    margin-top: 2rem;
    padding: 1rem;
    border-top: 1px solid #ccc;
}

.product-review {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-bottom: 1px solid #eee;
}
