
.login-form-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.login-form-page__logo-container img {
    width: 250px;
    height: auto;
    margin-bottom: 20px;
}
.login-form-page__content-container {
    background-color: #fff;
    padding: 70px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-form-page h1 {
    text-align: center;
    margin-bottom: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.login-form__label {
    position: relative;
    width: 100%;
}

.login-form__input {
    padding-right: 120px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    width: 100%;
}

.login-form__input:focus {
    border-color: #0077b6;
}

.login-form__error-message {
    color: red;
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin-bottom: 1px;
}


.login-form__button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #fddd4e;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-form__button:hover {
    background-color: #fccc2e;
}

.login-form-page__new-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.login-form-page__new-user-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #fddd4e;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-form-page__new-user-button:hover {
    background-color: #fccc2e;
}

/* Login form  button */
.login-form-page .login-form .login-form__button{
    transform:translatex(92px) translatey(27px);
    width:67%;
   }
