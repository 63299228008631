#root {
    overflow: hidden;
}

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.signup-logo {
    width: 250px;
    height: auto;
    margin-bottom: 20px;
}

.signup-form-container {
    background-color: #fff;
    padding: 50px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input-container {
  position: relative;
  width: 100%;
}

input {
    padding-right: 120px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    width: 100%;
}

input:focus {
    border-color: #0077b6;
}

.signup-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #fddd4e;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signup-button:hover {
    background-color: #fccc2e;
}

.error-message {
    /* position: absolute; */
    top: 40px;
    /* top: -20px; */
    left: 0;
    color: red;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    pointer-events: none;
}

#root .signup-form-container {
    transform: translatey(-55px);
    width: 587px;
}

#root .signup-container img {
    transform: translatey(-95px);
}

#root h1 {
    display: flex;
    position: relative;
    left: 82px;
}
