.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.not-found-heading {
    margin-bottom: 20px;
}

.not-found-img {
    max-width: 80%;
    height: auto;
}
