.complete-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 550px;
    height: auto;
    max-height: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    font-family: Arial, sans-serif;
}

.complete-heading {
    margin-bottom: 20px;
}

.complete-logo {
    width: 400px;
    margin: 20px 0;
}

.complete-button-complete {
    max-width: 100%;
    font-size: 1rem;
    background-color: #fddd4e;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    margin-left: 40px;
    margin-right: 40px
}

.complete-button-complete:hover {
    background-color: #fccc2e;
}

.buttons-container2 {
    display: flex;
    padding-top: 20px;
    justify-content: center;
    width: 100%;
}

/* List Item */
.profile-dropdown .user-details li{
    transform:translatex(-26px) translatey(0px);
    width:130px;
   }

   /* User details */
   .nav-login-item .profile-dropdown .user-details{
    width:153px;
    transform:translatex(-21px) translatey(0px);
   }
