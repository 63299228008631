.footer-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(19,25,33);
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: auto;
  }

  .content-area {
    padding-bottom: 14px;
}

  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .footer-list {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .footer-list li {
    margin: 0 15px;
    text-align: center;
  }

  .footer-logo-image {
    width: auto;
    max-height: 50px;
    height: auto;
    margin-right: 10px;
  }

  .footer-disclaimer {
    color: #888;
    font-size: 10px;
    margin-top: 5px;
  }

  .footer-icon {
    font-size: 24px;
    color: #FFF;
  }

  .footer-icon:hover {
    opacity: 0.8;
  }
