.review-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 10%;
}

.product-image-container img {
  max-width: 400px;
  max-height: 400px;
  display: block;
  margin: 0 auto;
}

.review-form-container {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review-box {
  position: relative;
  width: 300px;
  height: 100px;
  padding-right: 60px;
  resize: none;
  margin-bottom: 10px;
}

.character-counter {
  padding: 0 5px;
}

.submit-button {
  background-color: #fddd4e;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #fccc2e;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cartComp-confirm-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
  z-index: 1001;
}

.delete-text {
  display: flex;
  justify-content: center;
  color: black;
  align-content: center;
}

/* Delete button */
#root .cartComp-confirm-modal .delete-button{
  margin-right: 59px;
  margin-left: auto;
  width: 69px;
  min-height: 32px;
}

/* Form Division */
#root form{
  width:586px;
  transform:translatex(0px) translatey(0px);
 }

 /* Text Area */
 #root form textarea{
  width:522px;
  font-family:Arial,'Helvetica Neue',Helvetica,sans-serif;
 }

 /* Rating container */
 #root form .rating-container{
  display:flex;
  justify-content:center;
  font-size:23px;
 }

/* Review container */
#root .review-container{
  height:180px;
 }
 .not-signed-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  gap: 20px;
}

.not-signed-in-header {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.not-signed-in-logo {
  width: 400px;
  height: auto;
}

.not-signed-in-login-button {
  background-color: #fddd4e;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.not-signed-in-login-button:hover {
  background-color: #fae275;
}
