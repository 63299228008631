.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}

.splash-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    z-index: 0;
    top: -200px;
    padding-top: 12%;
}


.image-gallery-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    z-index: 0;
    top: -200px;
    padding-top: 5%;
}

.product-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1vw;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 80%;
    margin-top: -15%;
    padding-bottom: 1%;
}

.square {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1vh 0;
    cursor: pointer;
    height: 300px;
    width: 300px;
    position: relative;
}

.square img {
    width: 70%;
    height: 70%;
    object-fit: contain;
    margin-bottom: 1vh;
}

.square h2 {
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: #000;
    padding: 1vh 2vw;
    margin: 0;
}
